type Primitive = string | number | boolean | null;

type AnyObject = { [key: string]: any };

export function replaceUndefined<T>(input: T, replacement: Primitive): T {
	if (Array.isArray(input)) {
		return input.map(item => replaceUndefined(item, replacement)) as unknown as T;
	} else if (input !== null && typeof input === 'object') {
		return Object.keys(input).reduce((acc, key) => {
			const value = (input as AnyObject)[key];
			(acc as AnyObject)[key] = replaceUndefined(value, replacement);
			return acc;
		}, {} as T);
	} else if (input === undefined) {
		return replacement as T;
	}
	return input;
}
