import React from 'react';
import { ReactSVG } from 'react-svg';

import * as S from './styles';

interface SvgIconProps {
	path: string;
	color?: string;
	size?: number;
}

const SvgIcon: React.FC<SvgIconProps> = props => {
	return (
		<S.Container color={props.color}>
			<ReactSVG
				src={props.path}
				beforeInjection={svg => {
					// Adicione a cor ao SVG
					svg.setAttribute('fill', props.color || '#000');
					// Defina o tamanho
					svg.setAttribute('width', `${props.size}px`);
					svg.setAttribute('height', `${props.size}px`);
				}}
			/>
		</S.Container>
	);
};

export default SvgIcon;
