import { Box, RadioGroupProps, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import * as React from 'react';
import { useState } from 'react';

interface RadioOption {
	value: string;
	label: string;
}

interface RadioGroupCustomProps extends RadioGroupProps {
	externallabel?: string;
	defaultValue?: string;
	onChangeValue: (option: RadioOption) => void;
	options: RadioOption[];
	disabled?: boolean;
}

const RadioGroupCustom: React.FC<RadioGroupCustomProps> = props => {
	const [selectedValue, setSelectedValue] = useState(props.defaultValue);

	const handleChange = (value: string, label: string) => {
		const selectedOption: RadioOption = { value, label };
		props.onChangeValue(selectedOption);
		setSelectedValue(value);
	};

	return (
		<Box
			sx={{
				'.Mui-checked': {
					color: '#000' /* Substitua com a cor desejada */
				}
			}}
		>
			{props.externallabel && (
				<Typography
					variant="h2"
					sx={{
						fontSize: '18px',
						fontWeight: '500',
						marginBottom: '5px',
						textTransform: 'uppercase'
					}}
				>
					{props.externallabel}
				</Typography>
			)}
			<FormControl sx={{ marginBottom: '40px' }} disabled={props.disabled}>
				<RadioGroup
					row
					sx={{ gap: '20px', flexWrap: 'nowrap' }}
					value={selectedValue}
					onChange={e => {
						const selectedOption = props.options.find(option => option.value === e.target.value);
						if (selectedOption) {
							handleChange(selectedOption.value, selectedOption.label);
						}
					}}
				>
					{props.options.map(option => (
						<FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
					))}
				</RadioGroup>
			</FormControl>
		</Box>
	);
};

export default RadioGroupCustom;
