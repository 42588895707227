import { Tooltip } from '@mui/material';
import React from 'react';

import * as S from './styles';
import { ChatProps } from '../../../../utils/typesData';

interface AvatarProps {
	item: ChatProps;
}

function Avatar({ item }: AvatarProps): React.ReactElement {
	const getNameLetters = (name?: string) => {
		if (!name) return;
		const names = name.split(' ');
		const letters = names.map(name => name.charAt(0).toUpperCase());
		return letters.slice(0, 2).join('');
	};

	return (
		<Tooltip title={item.user?.name}>
			{item?.user?.avatar ? (
				<S.AvatarImage src={item?.user?.avatar} show={item.position == 'end' || item.position == 'only'} />
			) : (
				<S.AvatarText show={item.position == 'end' || item.position == 'only'}>
					{getNameLetters(item.user?.name)}
				</S.AvatarText>
			)}
		</Tooltip>
	);
}

export default Avatar;
