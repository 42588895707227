import React from 'react';

import ProcedureItemForm from './components/ProcedureItemForm';
import HeaderPage from '../../components/HeaderPage';
import LayoutBase from '../../components/LayoutBase';

const ProcedureItem: React.FC = () => {
	return (
		<LayoutBase>
			<HeaderPage title="Procedimentos" icon="/images/icons/IconTimeRed.svg" />
			<ProcedureItemForm />
		</LayoutBase>
	);
};

export default ProcedureItem;
