import React from 'react';

import * as S from './styles';

interface LoadingProps {
	open?: boolean;
}

const Loading: React.FC<LoadingProps> = props => {
	return (
		<>
			{props.open && (
				<S.Container>
					<img src="/images/logoLuvison.svg" alt="logotipo" />
				</S.Container>
			)}
		</>
	);
};

export default Loading;
