import styled from 'styled-components';

interface Props {
	color?: string;
}

export const Container = styled.div<Props>`
	.cls-1 {
		fill: ${props => (props.color ? props.color : '#000')};
	}
`;
