import { getToken } from 'firebase/messaging';
import { messaging } from '../config/firebaseDb';
import { notification } from '../services/notificationService';

export async function requestPermission(userId: string) {
	try {
		const permission = await Notification.requestPermission();
		if (permission === 'granted') {
			const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
			// Wait for serviceWorker.ready
			await navigator.serviceWorker.ready;

			const token = await getToken(messaging, {
				vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
				serviceWorkerRegistration: registration
			});

			localStorage.setItem('fcmToken', token);

			await notification.setUserDevice({ userId, token });

			let group;

			group = await notification.getGroup(userId);

			if (!group) group = await notification.createGroup({ groupName: userId, tokens: [token] });
			else await notification.addGroup({ groupName: userId, groupToken: group.notification_key, tokens: [token] });

			localStorage.setItem('fcmGroupToken', group.notification_key);
		} else {
			console.log('Permissão para notificações negada.');
		}
	} catch (error) {
		console.error('Erro ao obter token FCM:', error);
	}
}
