import React from 'react';
import { useNavigate } from 'react-router-dom';

import * as S from './styles';

const logo = '/images/logoLuvison.svg';

const LogoLuvison = () => {
	const navigate = useNavigate();
	const goHome = () => {
		navigate('/home');
	};
	return (
		<S.Container onClick={goHome}>
			<img src={logo} alt="logotipo" />
			<div>
				<h2>LUVISON CARVALHO</h2>
				<h3>ADVOGADOS</h3>
			</div>
		</S.Container>
	);
};

export default LogoLuvison;
