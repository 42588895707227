import React, { useState, useEffect, useMemo } from 'react';
import * as XLSX from 'xlsx';
import { fetchAstreaCases } from '../../services/procedureService';
import type { Column, TableProps } from '../../components/Table/Table';
import type { ProcedureAstrea } from '../../@types/procedure';
import Table from '../../components/Table/Table';
import LayoutBase from '../../components/LayoutBase';
import HeaderPage from '../../components/HeaderPage';
import type { dataPropsCli } from '../../@types/customer';
import { fetchCustomers } from '../../services/customerService';
import { auth } from '../../config/firebaseDb';
import SelectCustom from '../../components/Globals/SelectCustom';
import { localizeNumber } from '../../utils/localizeNumber';
import styled from 'styled-components';
import ButtonCustom from '../../components/Globals/ButtonCustom';
import alasql from 'alasql';
import dayjs from 'dayjs';
import Tooltip from '@mui/material/Tooltip';
import { Link } from '@mui/icons-material';

const labels: Record<string, string> = {
	DescUltimoHistorico: 'Último Histórico'
};

export default function ReportProcedure() {
	const [user, setUser] = useState<any>(null);
	const [procedures, setProcedures] = useState<
		(ProcedureAstrea & { inner?: TableProps<{ title: string; value: string }> })[]
	>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedCliId, setSelectedCliId] = useState('');
	const [selectedCli, setSelectedCli] = useState('');
	const [listCli, setListCli] = useState<dataPropsCli[]>([]);

	const fetchDataCli = async () => {
		if (user?.uid) {
			const dataCli = (await fetchCustomers(user)) ?? [];
			setListCli(dataCli.map(data => ({ ...data, id: data.astreaCod }))); //Usa o astreaCod para procurar os dados
		}
	};

	function handleDate(date: string) {
		if (!date) return '';
		return new Date(date.slice(0, 10)).toLocaleString('pt-br', {
			dateStyle: 'short'
		});
	}

	function exportToExcel() {
		alasql.setXLSX(XLSX);
		alasql(
			`
		SELECT 
			Tipo, 
			Titulo, 
			PapelCliente, 
			Acao, 
			Numero, 
			DtDistribuicao, 
			Objeto, 
			VlCausa, 
			Etiquetas, 
			DtUltimoHistorico, 
			DescUltimoHistorico, 
			UrlProcesso 
		INTO XLSX("${selectedCliId}-${dayjs().format('YYMMDD')}.xlsx", ?)
		FROM ?
		`,
			[
				{
					headers: true
				},
				procedures
			]
		);
	}

	const columns = useMemo<Column<ProcedureAstrea>[]>(
		() => [
			{
				key: 'Tipo',
				title: 'Tipo',
				value: data => data.Tipo,
				sortable: true,
				maxWidth: 55
			},
			{
				key: 'Titulo',
				title: 'Título',
				value: data => (
					<TitleContainer>
						<span>{data.Titulo}</span>
						<span>Processo: {data.Numero}</span>
					</TitleContainer>
				),
				sortable: true,
				maxWidth: 300
			},
			{
				key: 'Acao',
				title: 'Ação / Foro',
				value: data => <div>{data.Acao}</div>,
				maxWidth: 110,
				sortable: true
			},
			{
				key: 'VlCausa',
				title: 'Valor Causa',
				value: data => <div>R$ {localizeNumber(data?.VlCausa ?? 0)}</div>,
				maxWidth: 110,
				sortable: true
			},
			{
				key: 'Objeto',
				title: 'Objeto',
				value: data => data.Objeto,
				maxWidth: 200
			},
			{
				key: 'DtUltimoHistorico',
				title: 'Ult. Mov.',
				value: data => <div>{handleDate(data.DtUltimoHistorico)}</div>,
				sortable: true
			},
			{
				key: 'UrlProcesso',
				title: 'Link',
				value: data =>
					data?.UrlProcesso && (
						<a href={data.UrlProcesso} target="_blank" rel="noreferrer">
							<Link />
						</a>
					)
			}
		],
		[selectedCli]
	);

	useEffect(() => {
		fetchDataCli();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(user => {
			setUser(user);
		});
		return () => unsubscribe();
	}, []);

	useEffect(() => {
		if (selectedCliId) {
			fetchAstreaCases(selectedCliId)
				.then(cases => {
					setIsLoading(true);
					const data = cases.map(item => {
						const data = [];
						for (const key in item) {
							const k = key as keyof ProcedureAstrea;
							const title = labels[k];
							if (title) {
								data.push({ title, value: item[k] ?? '' });
							}
						}
						return {
							...item,
							inner: {
								columns: [
									{
										key: 'title',
										value: data => data.title,
										verticalAlign: 'top',
										maxWidth: 55
									},
									{
										key: 'value',
										value: data => data.value,
										verticalAlign: 'top'
									}
								],
								headerHidden: true,
								data
							}
						} as ProcedureAstrea & { inner: TableProps<{ title: string; value: string }> };
					});
					setProcedures(data);
				})
				.finally(() => setIsLoading(false));
		}
	}, [user, selectedCliId]);

	return (
		<LayoutBase>
			<HeaderPage
				title="Rel. Processos e Casos"
				description="Clique no vínculo para visualizar"
				icon="/images/icons/IconReports.svg"
			/>

			<SelectCustom
				externallabel="SELECIONE O VÍNCULO"
				id={selectedCliId}
				setId={setSelectedCliId}
				value={selectedCli}
				setValue={setSelectedCli}
				data={listCli}
			/>

			{selectedCli && (
				<>
					<ButtonContainer>
						<Tooltip title={'Exportar com todos os dados'}>
							<ButtonCustom
								color="error"
								variant="contained"
								sx={{ minWidth: 0, width: 'fit-content', marginBottom: '30px' }}
								onClick={exportToExcel}
								disabled={procedures.length === 0}
							>
								Exportar para excel
							</ButtonCustom>
						</Tooltip>
					</ButtonContainer>
					<Table columns={columns} data={procedures} isLoading={isLoading} />
				</>
			)}
		</LayoutBase>
	);
}

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1;
`;
