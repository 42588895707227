import { AccessTime, CheckCircleOutline } from '@mui/icons-material';
import { AttachFile } from '@mui/icons-material/';
import { Box, Tooltip } from '@mui/material';
import React from 'react';

import * as S from './styles';

export interface CardItemStatus {
	code: string;
	statusDes?: string;
}

interface CardListItemProps {
	id?: string;
	key?: number;
	title?: string;
	description?: string;
	code?: string;
	status?: CardItemStatus;
	date?: string;
	document?: boolean;
	isProcedure?: '';
	handleClick: (item: CardListItemProps) => void;
}

const CardListItem: React.FC<CardListItemProps> = props => {
	function handleCardIcon() {
		let Icon: React.ReactNode;
		if (props.document) {
			Icon = (
				<AttachFile
					sx={{
						color: '#444',
						fontSize: '30px',
						marginRight: '20px',
						transform: 'rotate(-30deg)',
						cursor: 'pointer'
					}}
				/>
			);
		}
		if (props.status?.code == '1') {
			Icon = (
				<Tooltip title={props.status?.statusDes ?? 'Nova/Pendente'}>
					<AccessTime sx={{ color: '#a9a9a9', fontSize: '28px' }} />
				</Tooltip>
			);
		}
		if (props.status?.code == '2') {
			Icon = (
				<Tooltip title={props.status?.statusDes ?? 'Lida'}>
					<CheckCircleOutline sx={{ color: '#ffc107', fontSize: '28px' }} />
				</Tooltip>
			);
		}

		if (props.status?.code == '11') {
			Icon = (
				<Tooltip title={props.status?.statusDes ?? 'Em atendimento'}>
					<CheckCircleOutline sx={{ color: '#0dcaf0', fontSize: '28px' }} />
				</Tooltip>
			);
		}

		if (props.status?.code == '99') {
			Icon = (
				<Tooltip title={props.status?.statusDes ?? 'Finalizada'}>
					<CheckCircleOutline sx={{ color: '#68a151', fontSize: '28px' }} />
				</Tooltip>
			);
		}
		return Icon;
	}

	return (
		<S.Container
			key={props.key}
			onClick={() => {
				// if (!props.code) return;
				// props.handleClick(props.code);
				// if (!props.id) return;
				props.handleClick(props);
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					justifyContent: 'space-between'
				}}
			>
				<div style={{ maxWidth: props.document ? '420px' : '800px' }}>
					<S.Title>{props.title}</S.Title>
					<S.Description>{props.description}</S.Description>
				</div>
				<S.Code>{props.code}</S.Code>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-end',
					justifyContent: props.document ? 'end' : 'space-between',
					marginRight: '20px'
				}}
			>
				{handleCardIcon()}
				{props.date && <S.Code>{`DATA: ${props.date}`}</S.Code>}
			</Box>
		</S.Container>
	);
};

export default CardListItem;
