import { Menu } from '@mui/icons-material';
import { Instagram, LinkedIn } from '@mui/icons-material';
import { Box } from '@mui/material';
import React, { useContext } from 'react';

import * as S from './styles';
import { LayoutBaseContext } from '../LayoutBase';
import LogoLuvison from '../LogoLuvison';

const URL_LINKEDIN = 'https://www.linkedin.com/company/luvisonadv';
const URL_INSTAGRAM = 'https://www.instagram.com/luvisonadv';

const Topbar: React.FC = () => {
	const { showSidebar, toggleSidebar } = useContext(LayoutBaseContext);

	const openLink = (url: string) => {
		window.open(url, '_blank'); // '_blank' abre em uma nova aba
	};

	return (
		<S.Container>
			<Box
				sx={{
					display: 'flex',
					gap: '40px',
					alignItems: 'center',
					cursor: 'pointer'
				}}
			>
				<S.WrapperIcon showsidebar={showSidebar ? 1 : 0}>
					<Menu
						onClick={toggleSidebar}
						sx={{
							color: showSidebar ? '#fff' : '#110d28',
							width: '50px',
							height: '50px',
							cursor: 'pointer'
						}}
					/>
				</S.WrapperIcon>
				<LogoLuvison />
			</Box>
			<S.IsDesktop>
				<Box sx={{ display: 'flex', gap: '20px', marginRight: '40px' }}>
					<LinkedIn sx={{ fontSize: '35px', cursor: 'pointer' }} onClick={() => openLink(URL_LINKEDIN)} />
					<Instagram sx={{ fontSize: '35px', cursor: 'pointer' }} onClick={() => openLink(URL_INSTAGRAM)} />
				</Box>
			</S.IsDesktop>
		</S.Container>
	);
};

export default Topbar;
