import React from 'react';

import { ConsultRequestForm } from './components/ConsultRequestForm';
import HeaderPage from '../../components/HeaderPage';
import LayoutBase from '../../components/LayoutBase';

const ConsultRequest: React.FC = () => {
	return (
		<LayoutBase>
			<HeaderPage
				title="Consultar Solicitação"
				description="Selecione a solicitação para mais detalhes" //Encontre por status, nome ou data
				icon="/images/icons/IconTimeRed.svg"
			/>
			<ConsultRequestForm />
		</LayoutBase>
	);
};

export default ConsultRequest;
