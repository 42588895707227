import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
	{
		label: 'Sua empresa mais protegida.',
		description:
			'Seja bem-vindo ao escritório de Advocacia Virtual Luvison Carvalho Advogados. Oferecemos Assessoria Jurídica Completa para você.',
		imgPath: '/images/carousel1.svg'
	},
	{
		label: 'Tenha o jurídico da sua empresa na palma da sua mão.',
		description:
			'Um aplicativo completo para você se manter informado sobre as demandas jurídicas. Solicite serviços e acompanhe os procedimentos em um só lugar.',
		imgPath: '/images/carousel2.svg'
	},
	{
		label: 'Visão multidisciplinar para melhorar o desempenho da sua empresa',
		description:
			'Auxiliamos no mapeamento de riscos jurídicos e na identificação e viabilização de oportunidades, visando a geração de resultados para a melhoria do desempenho empresarial.',
		imgPath: '/images/carousel3.svg'
	}
];

const Carousel: React.FC = () => {
	const theme = useTheme();
	const [activeStep, setActiveStep] = React.useState(0);

	const handleStepChange = (step: number) => {
		setActiveStep(step);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				flexDirection: 'column',
				width: '100%',
				height: 'max-content',
				padding: '0 50px',
				maxWidth: 700,

				'@media (max-width: 1000px)': {
					height: '100vh',
					justifyContent: 'flex-start',
					alignItems: 'flex-start',
					padding: '20px'
				},
				'@media (max-width: 400px)': {
					maxHeight: '410px',
					padding: '0px 20px',
					margin: '0px'
				},
				'@media (min-width: 1024px)': {
					padding: '0px 20px',
					justifyContent: 'center',
					margin: '0px'
				}
			}}
		>
			<AutoPlaySwipeableViews
				axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
				index={activeStep}
				onChangeIndex={handleStepChange}
				enableMouseEvents
				style={{ width: '100%' }}
			>
				{images.map((step, index) => (
					<div
						key={step.label}
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center'
						}}
					>
						{Math.abs(activeStep - index) <= 2 ? (
							<Box
								component="img"
								sx={{
									width: '100%',
									maxWidth: 500,
									marginBottom: '20px',
									'@media (max-width: 400px)': {
										height: '120px'
									}
								}}
								src={step.imgPath}
								alt={step.label}
							/>
						) : null}
						<Typography
							sx={{
								width: '100%',
								maxWidth: '550px',
								fontSize: 'clamp(1.75em, 1em + 3vw, 2.5em)',
								fontWeight: 'bold',
								textAlign: 'center',
								marginBottom: '20px',
								'@media (max-width: 1000px)': {
									fontSize: '1.5em'
								},
								'@media (max-width: 400px)': {
									fontSize: '1.2em'
								},
								'@media (min-width: 1024px)': {
									fontSize: '1.3em'
								}
							}}
						>
							{step.label}
						</Typography>
						<Typography
							sx={{
								width: '100%',
								maxWidth: '650px',
								fontSize: '22px',
								textAlign: 'center',
								'@media (max-width: 1000px)': {
									fontSize: '16px'
								},
								'@media (min-width: 1024px)': {
									fontSize: '0.9em'
								}
							}}
						>
							{step.description}
						</Typography>
					</div>
				))}
			</AutoPlaySwipeableViews>

			<Box
				sx={{
					display: 'flex',
					width: '100%',
					justifyContent: 'center',
					marginTop: '50px',
					'@media (max-width: 400px)': {
						marginTop: '20px'
					},
					'@media (min-width: 1024px)': {
						marginTop: '20px'
					}
				}}
			>
				<Box
					sx={{
						display: 'flex',
						width: 'max-content',
						textAling: 'center',
						gap: '17px',
						button: {
							minWidth: '11px',
							minHeight: '11px',
							maxWidth: '11px',
							maxHeight: '11px',
							background: '#999',
							borderRadius: '50%',
							cursor: 'pointer',
							padding: '0',
							border: 'none'
						}
					}}
				>
					<button
						onClick={() => {
							handleStepChange(0);
						}}
						style={{ background: activeStep === 0 ? '#ba3533' : '#999' }}
					></button>
					<button
						onClick={() => {
							handleStepChange(1);
						}}
						style={{ background: activeStep === 1 ? '#ba3533' : '#999' }}
					></button>
					<button
						onClick={() => {
							handleStepChange(2);
						}}
						style={{ background: activeStep === 2 ? '#ba3533' : '#999' }}
					></button>
				</Box>
			</Box>
		</Box>
	);
};

export default Carousel;
