import { AttachFile, CheckCircleOutline } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { User } from 'firebase/auth';
import { addDoc, collection } from 'firebase/firestore';
import React, { type Dispatch, type MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import Avatar from './Avatar';
import * as S from './styles';
import ButtonCustom from '../../../../components/Globals/ButtonCustom';
import InputCustom from '../../../../components/Globals/InputCustom';
import db, { auth } from '../../../../config/firebaseDb';
import { criarMensagemAnexo, removerArquivo } from '../../../../services/chatServices';
import { getLocalGlobalConfig } from '../../../../services/configService';
import { megabytesToBytes } from '../../../../utils/megabyteToBytes';
// import { months } from '../../../../utils/mockup';
import { ChatProps, ChatPropsFB } from '../../../../utils/typesData';

export interface ChatsProps {
	chat: ChatProps[];
	chatId: string;
	assunto: string;
	protocolo: string;
	finished?: boolean;
	openFile: (fileUrl: string) => void;
}

const Chat: React.FC<ChatsProps> = props => {
	const elementRef = useRef<HTMLDivElement>(null);
	const [userId, setUserId] = useState('');
	const [userName, setUserName] = useState('');
	const [msg, setMsg] = useState('');
	const [error, setError] = useState<Error>();
	const [loadingAttach, setLoadingAttach] = useState(false);
	const [loadingMsg, setLoadingMsg] = useState(false);
	const [chat, setChat] = useState<ChatProps[] | null>([
		{
			id: '0',
			type: 'none',
			source: 'none',
			message: 'none',
			time: 'none',
			position: 'only',
			user: {
				uid: 'none',
				name: 'none',
				avatar: 'none'
			}
		}
	]);
	const [user, setUser] = useState<User>();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [selectedItem, setSelectedItem] = useState<ChatProps>();

	const handleMenuClick = (event: MouseEvent<HTMLElement>, chat: ChatProps) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
		setSelectedItem(chat);
	};

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(user => {
			if (user) {
				setUserId(user.uid);
				setUserName(user.displayName ?? user.email ?? '');
				setUser(user);
			} else {
				console.error('user not authenticate');
			}
		});
		return () => unsubscribe();
	}, []);
	const formattedChat = useMemo(() => {
		if (!chat) return chat;
		const chatMarkers: ChatProps[] = [];

		for (let i = 0; i < chat.length; i++) {
			const current = chat[i];
			const previous = chat[i - 1];

			if (!previous || !isSameDate(new Date(current.time), new Date(previous.time))) {
				chatMarkers.push({
					id: String(new Date(current.time).getTime()),
					time: current.time,
					type: 'message-time'
				});
			}
			chatMarkers.push(current);
		}
		return chatMarkers;
	}, [chat]);

	function isSameDate(date1: Date, date2: Date) {
		return (
			date1.getFullYear() === date2.getFullYear() &&
			date1.getMonth() === date2.getMonth() &&
			date1.getDate() === date2.getDate()
		);
	}

	function updatePositions(dataChat: ChatProps[]) {
		//console.log('dentro', dataChat);
		if (dataChat == null) return;
		const updateChat: ChatProps[] = dataChat.map((item, index) => {
			const beforeChat = dataChat[index - 1];
			const currentChat = dataChat[index];
			const afterChat = dataChat[index + 1];

			if (currentChat?.type == 'message-time') {
				return { ...item };
			}
			if (beforeChat?.type == 'message-time') {
				if (afterChat?.source != currentChat?.source) {
					return { ...item, position: 'only' };
				}
				return { ...item, position: 'start' };
			}

			if (beforeChat?.source != currentChat?.source && afterChat?.source != currentChat?.source) {
				return { ...item, position: 'only' };
			}

			if (beforeChat?.source == currentChat?.source && afterChat?.source == currentChat?.source) {
				return { ...item, position: 'middle' };
			}

			if (!afterChat?.source || afterChat?.source != currentChat?.source) {
				return { ...item, position: 'end' };
			}

			if (beforeChat?.type != 'message' && afterChat?.type != 'message') {
				return { ...item, position: 'only' };
			}

			return { ...item, position: 'start' };
		});
		setChat([...updateChat]);
		return;
	}

	const getDateTimeToDate = (data?: string) => {
		if (!data) return;

		return new Date(data).toLocaleDateString('pt-BR', {
			year: '2-digit',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit'
		});
	};

	// const getDateToDate = (date?: string) => {
	// 	if (!date) return;
	// 	const data = new Date(date);
	// 	const day: number = data.getDate();
	// 	const month: number = data.getMonth();
	// 	const year: number = data.getFullYear();
	// 	const dataFormatada: string = `${day.toString()}  ${months[month]}, ${year.toString()}`;
	// 	return dataFormatada;
	// };

	// 	NOT USED ANYMORE
	//	const getHourToDate = (date?: string) => {
	// 	if (!date) return;
	// 	const data = new Date(date);
	// 	const horas: number = data.getHours();
	// 	const minutos: number = data.getMinutes();
	// 	const horasFormatadas: string = horas < 10 ? '0' + horas : horas.toString();
	// 	const minutosFormatados: string = minutos < 10 ? '0' + minutos : minutos.toString();
	// 	const horaFormatada: string = horasFormatadas + ':' + minutosFormatados;
	// 	return horaFormatada;
	// };

	const handleSendMsg = (chatId: string) => {
		if (!msg) return;
		setError(undefined);

		const newMessage: ChatProps = {
			id: (chat && chat.length + '1') || '0',
			type: 'message',
			source: 'sent',
			message: msg,
			time: new Date().toString(),
			// position: 'middle',
			user: {
				uid: userId,
				name: userName,
				avatar: ''
			}
		};

		const _addMessage = async (msg: ChatProps) => {
			// if(msg?.user? == null) {
			// 		throw('Usuário não identificado!');
			// 	}
			//console.log('_addMessage >>> ', msg);
			setLoadingMsg(true);
			const msgFB: ChatPropsFB = {
				_id: uuidv4(),
				text: msg.message ?? '',
				Texto: msg.message ?? '',

				DthRegistro: new Date(),
				createdAt: new Date(),

				UidRegistro: msg?.user?.uid ?? '',
				NomeRegistro: msg?.user?.name ?? '',

				Plataforma: 'web-cliente',
				user: {
					_id: userId,
					uid: userId,
					name: userName
				}
			};

			/*
				type: 'file',
				message: 'Abrir Arquivo',
				url: mensagem.ImagemUrl,
			*/

			if (msg.type == 'image') {
				// msgFB.image = msg.fileUrl ?? '';
				// msgFB.ImagemUrl = msg.fileUrl ?? '';
				msgFB.file = msg.fileUrl ?? '';
				msgFB.FileUrl = msg.fileUrl ?? '';
			}
			if (msg.type == 'file') {
				msgFB.Texto = 'Abrir Arquivo';
				msgFB.file = msg.fileUrl ?? '';
				msgFB.FileUrl = msg.fileUrl ?? '';
			}

			await addDoc(collection(db, `AppContato/${chatId}/Mensagem`), msgFB).catch(() => setLoadingMsg(false));

			setLoadingMsg(false);
		};

		_addMessage(newMessage);
		// const updatedChat = chat ? [...chat, newMessage] : [newMessage];
		// updatePositions(updatedChat);
		setMsg('');
	};

	async function handleUpload(target: EventTarget & HTMLInputElement) {
		setError(undefined);
		setLoadingAttach(true);
		const files = target.files;
		if (!files) return;

		const arquivo = files.item(0);

		const limiteSize = 2.5;
		const limitSizeBytes = megabytesToBytes(limiteSize);

		if (!arquivo?.size) return;

		if (arquivo?.size > limitSizeBytes) {
			setError({
				message: `O arquivo excede o tamanho máximo permitido de ${limiteSize}MB`,
				name: 'Anexo excede o tamanho'
			});
			target.value = '';
			return;
		}

		if (!arquivo) return;
		if (!user) return;

		await criarMensagemAnexo([{ arquivo, descricao: arquivo.name }], props.chatId, user).catch(
			() => (target.value = '')
		);
		target.value = '';
		setLoadingAttach(false);
	}

	useEffect(() => {
		updatePositions(props.chat);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.chat]);

	useEffect(() => {
		if (elementRef.current) {
			elementRef.current.scrollTop = elementRef.current.scrollHeight;
		}
	}, [chat]);

	return (
		<S.Container>
			<MenuAttachment
				anchorEl={anchorEl}
				chat={selectedItem}
				chatId={props.chatId}
				openFile={props.openFile}
				setAnchorEl={setAnchorEl}
			/>
			<S.WrappetTitle>
				<S.Title>{props.assunto}</S.Title>
				<S.DescriptionTitle>Protocolo: {props.protocolo}</S.DescriptionTitle>
			</S.WrappetTitle>
			<S.WrapperMsgs ref={elementRef}>
				{formattedChat &&
					formattedChat.map(item => (
						<React.Fragment key={item.id}>
							{/* QUANDO APARECER A DIVISÃO POR DIA EXIBIR AVATARES (AGRUPAR POR DIA)
							 {item.type == 'message-time' && <S.MsgsTime>{getDateToDate(item.time)}</S.MsgsTime>}  
							 */}
							{item.type == 'message' && item.source == 'sent' && (
								<S.WrapperAvatar>
									<S.WrapperSentMsg position={item.position}>
										<S.SentMsg>{item.message}</S.SentMsg>
										<S.MsgTimeSent>{getDateTimeToDate(item.time)}</S.MsgTimeSent>
									</S.WrapperSentMsg>
									<Avatar item={item} />
								</S.WrapperAvatar>
							)}
							{item.type == 'file' && item.source == 'sent' && (
								<S.WrapperAvatar>
									<S.WrapperSentFile position={item.position} onClick={e => handleMenuClick(e, item)}>
										<S.WrapperIconAttach>
											<S.IconAttachSent>
												<AttachFile />
											</S.IconAttachSent>
											<S.RecivedMsg>{item.message}</S.RecivedMsg>
										</S.WrapperIconAttach>
										<S.MsgTimeSent>{getDateTimeToDate(item.time)}</S.MsgTimeSent>
									</S.WrapperSentFile>
									<Avatar item={item} />
								</S.WrapperAvatar>
							)}
							{item.type == 'message' && item.source == 'recived' && (
								<S.WrapperAvatar>
									<Avatar item={item} />
									<S.WrapperRecivedMsg position={item.position}>
										<S.RecivedMsg>{item.message}</S.RecivedMsg>
										<S.MsgTimeRecived>{getDateTimeToDate(item.time)}</S.MsgTimeRecived>
									</S.WrapperRecivedMsg>
								</S.WrapperAvatar>
							)}
							{item.type == 'file' && item.source == 'recived' && (
								<S.WrapperAvatar>
									<Avatar item={item} />
									<S.WrapperRecivedFile
										position={item.position}
										onClick={() => {
											if (item.fileUrl) props.openFile(item.fileUrl);
										}}
									>
										<S.WrapperIconAttach>
											<S.RecivedMsg>{item.message}</S.RecivedMsg>
											<S.IconAttach>
												<AttachFile />
											</S.IconAttach>
										</S.WrapperIconAttach>
										<S.MsgTimeRecived>{getDateTimeToDate(item.time)}</S.MsgTimeRecived>
									</S.WrapperRecivedFile>
								</S.WrapperAvatar>
							)}
						</React.Fragment>
					))}
			</S.WrapperMsgs>
			<S.Hr />

			{props.finished ? (
				<>
					<S.WapperFinished>
						<S.TextFinished>SOLICITAÇÃO FINALIZADA</S.TextFinished>
						<CheckCircleOutline sx={{ color: '#6da935' }} />
					</S.WapperFinished>
					{/* 					
					<S.WapperButton>
						<ButtonCustom variant="contained" sx={{ background: '#b9322a', height: '30px', maxWidth: '250px' }}>
							VOLTAR PARA HOME
						</ButtonCustom>
					</S.WapperButton> 
					*/}
				</>
			) : (
				<S.WrapperSendMsgInput>
					<InputCustom
						error={!!error}
						helperText={error?.message}
						size="small"
						value={msg}
						sx={{ marginBottom: '0px' }}
						onChange={e => setMsg(e.target.value)}
					/>
					<ButtonCustom
						variant="contained"
						color="error"
						sx={{ width: '100px', height: '40px' }}
						disabled={loadingMsg}
						onClick={() => handleSendMsg(props.chatId)}
					>
						{loadingMsg ? <CircularProgress size={15} color="inherit" /> : 'Enviar'}
					</ButtonCustom>

					<S.ButtonAttach disable={loadingAttach}>
						{loadingAttach ? <CircularProgress size={15} color="inherit" /> : <AttachFile />}
						<S.InputFile type="file" onChange={e => handleUpload(e.currentTarget)} />
					</S.ButtonAttach>
				</S.WrapperSendMsgInput>
			)}
		</S.Container>
	);
};

interface MenuAttachmentProps extends Pick<ChatsProps, 'openFile' | 'chatId'> {
	anchorEl: null | HTMLElement;
	chat?: ChatProps;
	setAnchorEl: Dispatch<React.SetStateAction<HTMLElement | null>>;
}

function MenuAttachment({ chat, openFile, chatId, anchorEl, setAnchorEl }: MenuAttachmentProps) {
	const open = Boolean(anchorEl);

	const handleMenuClose = (event: MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		setAnchorEl(null);
	};

	// eslint-disable-next-line
	async function handleRemoveFile(e: MouseEvent<HTMLLIElement, globalThis.MouseEvent>, message: ChatProps) {
		e.stopPropagation();

		await removerArquivo(message, chatId).catch(err => console.error(err));
		setAnchorEl(null);
	}

	function allowToRemove(chat: ChatProps) {
		const timeLimit = getLocalGlobalConfig()?.ChatTimeFileDelete ?? 120; //seconds
		const now = new Date();

		const date = new Date(chat.time);

		const diff = now.getTime() - date.getTime();

		const diffInMinutes = diff / 1000;
		return diffInMinutes <= timeLimit;
	}
	return (
		chat && (
			<Menu
				id="long-menu"
				MenuListProps={{
					'aria-labelledby': 'long-button'
				}}
				anchorEl={anchorEl}
				open={open}
				onClick={e => e.stopPropagation()}
				onClose={handleMenuClose}
			>
				<MenuItem
					onClick={e => {
						e.stopPropagation();
						if (chat.fileUrl) openFile(chat.fileUrl);
					}}
				>
					Abrir anexo
				</MenuItem>
				{allowToRemove(chat) && <MenuItem onClick={e => handleRemoveFile(e, chat)}>Remover anexo</MenuItem>}
			</Menu>
		)
	);
}

export default Chat;
