import type { User } from 'firebase/auth';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import db from '../config/firebaseDb';
import type { dataPropsCli } from '../@types/customer';

export async function fetchCustomers(user: User) {
	const q = query(
		collection(db, 'Cliente'),
		where('Usuario', 'array-contains', user?.uid),
		orderBy('NomeEmpresa', 'asc')
	);
	try {
		const querySnap = await getDocs(q);
		const dataCli: dataPropsCli[] = [];
		querySnap.forEach(doc => {
			const { NomeApelido, NomeEmpresa, AstreaCod } = doc.data();
			dataCli.push({
				id: doc.id,
				label: NomeApelido ? NomeApelido : NomeEmpresa,
				name: NomeApelido ? NomeApelido : NomeEmpresa,
				astreaCod: AstreaCod
			});
		});
		return dataCli;
	} catch (error) {
		console.error('[fetchDataCli] Err: ', error);
	}
}
