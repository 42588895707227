export const menuSidebar = [
	{
		id: 11,
		icon: '/images/icons/IconRequest.svg',
		title: 'Solicitar Serviço',
		link: '/service_request'
	},
	{
		id: 1,
		icon: '/images/icons/IconTime.svg',
		title: 'Solicitações',
		link: '/consult_request'
	},
	{
		id: 4,
		icon: '/images/icons/IconDocument.svg',
		title: 'Meus Documentos',
		link: '/documents'
	},
	{
		id: 3,
		icon: '/images/icons/IconProcedure.svg',
		title: 'Procedimentos',
		link: '/procedures'
	},
	{
		id: 2,
		icon: '/images/icons/IconHammer.svg',
		title: 'Meus Processos',
		link: '/lawsuits'
	},
	{
		id: 5,
		icon: '/images/icons/IconReports.svg',
		title: 'Rel. Processos e Casos',
		link: '/report-lawsuits'
	}
	// {
	// id: 5,
	// icon: '/images/icons/IconReport.svg',
	// title: 'Relatórios',
	// link: '/'
	// },
	// {
	// id: 6,
	// icon: '/images/icons/IconProfile.svg',
	// title: 'Perfil',
	// link: '/'
	// }
];

export const cards = [
	{
		id: 1,
		icon: '/images/icons/IconRequest.svg',
		link: '/service_request',
		title: 'Solicitar Serviço',
		description: 'DOCUMENTOS CONTRATOS, ETC'
	},
	{
		id: 2,
		icon: '/images/icons/IconTimeRed.svg',
		link: '/consult_request',
		title: 'Consultar Solicitações',
		description: 'FINALIZADAS OU EM ANDAMENTO'
	},
	{
		id: 3,
		icon: '/images/icons/IconDocumentRed.svg',
		link: '/documents',
		title: 'Documentos',
		description: 'FINALIZADOS'
	},
	{
		id: 4,
		icon: '/images/icons/IconProcedureRed.svg',
		link: '/procedures',
		title: 'Procedimentos',
		description: 'DEMANDAS EM CURSO / FINALIZADA'
	},
	{
		id: 5,
		icon: '/images/icons/IconHammerRed.svg',
		link: '/lawsuits',
		title: 'Processos',
		description: 'QUE ENVOLVEM SEU NEGÓCIO'
	}
];

export const requests = [
	{
		id: 1,
		title: 'Documento:',
		description: 'Contrato para fornecedor',
		code: '230425141719',
		status: 'pending'
	},
	{
		id: 2,
		title: 'Financeiro:',
		description: 'Recibo para funcionário',
		code: '230425141719',
		status: 'finished'
	},
	{
		id: 3,
		title: 'Documento:',
		description: 'Aditivo ao contrato de trabalho',
		code: '230425141719',
		status: 'finished'
	},
	{
		id: 4,
		title: 'Documento:',
		description: 'Aditivo ao contrato de trabalho',
		code: '230425141719',
		status: 'pending'
	},
	{
		id: 5,
		title: 'Documento:',
		description: 'Aditivo ao contrato de trabalho',
		code: '230425141719',
		status: 'pending'
	}
];

export const procedures = [
	{
		id: 1,
		title: 'Contrato de Marketing Digital',
		description: '',
		code: '230425141719',
		status: 'pending',
		date: '12.05.23'
	},
	{
		id: 2,
		title: 'Confecção de termo de responsabilidade e envio ao client',
		description: '',
		code: '230425141719',
		status: 'finished',
		date: '10.05.23'
	},
	{
		id: 3,
		title: 'Parecer Trabalhista:',
		description: 'Vale Transporte',
		code: '230425141719',
		status: 'finished',
		date: '10.05.23'
	},
	{
		id: 4,
		title: 'Atendimento:',
		description: 'Tiago Luvison',
		code: '230425141719',
		status: 'pending',
		date: '10.05.23'
	}
];

export const documents = [
	{
		id: 1,
		title: ' Aditivo ao contrato Individual do Trabalho Auxiliar de Vendas'
	},
	{
		id: 2,
		title: 'Termo de Confidencialidade e Responsabilidade Estagiario'
	},
	{
		id: 3,
		title: ' Declaração Adequação de Posto de Trabalho'
	}
];

export const companys = [
	{
		id: '1',
		label: 'Apple'
	},
	{
		id: '2',
		label: 'Samsung'
	},
	{
		id: '3',
		label: 'Riachuelo'
	}
];

export const optionsPriority = [
	{
		value: '1',
		label: 'ALTA'
	},
	{
		value: '2',
		label: 'MÉDIA'
	},
	{
		value: '3',
		label: 'BAIXA'
	}
];

export const optionsServices = [
	{
		id: 'DOCUMENTO',
		label: 'DOCUMENTO: Contratos, Termos e Declarações'
	},
	{
		id: 'FINANCEIRO',
		label: 'FINANCEIRO: Recibos, Pagamentos e Cobranças'
	},
	{
		id: 'CONSULTA',
		label: 'CONSULTAS: Agende com um de nossos especialistas'
	},
	{
		id: 'OUTRO',
		label: 'OUTRO: Especifique a solicitação'
	}
];

export const months = [
	'JANEIRO',
	'FEVEREIRO',
	'MARÇO',
	'ABRIL',
	'MAIO',
	'JUNHO',
	'JULHO',
	'AGOSTO',
	'SETEMBRO',
	'OUTUBRO',
	'NOVEMBRO',
	'DEZEMBRO'
];

export const chat = [
	{
		id: '1',
		type: 'message-time',
		time: 'Wed Aug 10 2022 13:40:00 GMT-0500 (Acre Standard Time)'
	},
	{
		id: '2',
		type: 'message',
		source: 'sent',
		message:
			'Gostaria de um termo para o funcionário asssinar que recebeu um material fornecido pela empresa. Caso: uma gestante foi atesta de que não pode trabalhar em [é por muito tempo, a loja forneceu cadeira para ela antes mesmo de vir esse atestado.',
		time: 'Wed Aug 10 2022 13:40:00 GMT-0500 (Acre Standard Time)',
		user: {
			uid: 'aw53f334556',
			name: 'Thiago B. Caldera',
			avatar: ''
		}
	},
	{
		id: '3',
		type: 'message-time',
		time: 'Thu May 18 2023 09:40:00 GMT-0500 (Acre Standard Time)'
	},
	{
		id: '4',
		type: 'message',
		source: 'recived',
		message: 'Bom dia, Paula. Segue a declaração solicitada.',
		time: 'Thu May 18 2023 09:40:00 GMT-0500 (Acre Standard Time)',
		user: {
			uid: 'aw53f334556',
			name: 'Emerson',
			avatar: ''
		}
	},
	{
		id: '5',
		type: 'file',
		source: 'recived',
		message: 'Abrir Arquivo',
		url: 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fpt.vecteezy.com%2Ffotos-gratis%2Fwallpaper&psig=AOvVaw0w4byCRXKVs3rQoZABH9M_&ust=1697809569275000&source=images&cd=vfe&opi=89978449&ved=0CBEQjRxqFwoTCNCN79OfgoIDFQAAAAAdAAAAABAE',
		time: 'Thu May 18 2023 09:40:00 GMT-0500 (Acre Standard Time)',
		user: {
			uid: 'aw53f334556',
			name: 'Emerson',
			avatar: ''
		}
	},
	{
		id: '6',
		type: 'message',
		source: 'recived',
		message: 'Coma a confirmação do recebimento, pedimos a gentileza que deixe sua avaliação de nosso atentimento.',
		time: 'Thu May 18 2023 09:40:00 GMT-0500 (Acre Standard Time)',
		user: {
			uid: 'aw53f334556',
			name: 'Emerson',
			avatar: ''
		}
	},
	{
		id: '7',
		type: 'message',
		source: 'recived',
		message: 'Coma a confirmação do recebimento, pedimos a gentileza que deixe sua avaliação de nosso atentimento.',
		time: 'Thu May 18 2023 09:40:00 GMT-0500 (Acre Standard Time)',
		user: {
			uid: 'aw53f334556',
			name: 'Emerson',
			avatar: ''
		}
	},
	{
		id: '20',
		type: 'message',
		source: 'sent',
		message:
			'Gostaria de um termo para o funcionário asssinar que recebeu um material fornecido pela empresa. Caso: uma gestante foi atesta de que não pode trabalhar em [é por muito tempo, a loja forneceu cadeira para ela antes mesmo de vir esse atestado.',
		time: 'Wed Aug 10 2022 13:40:00 GMT-0500 (Acre Standard Time)',
		user: {
			uid: 'aw53f334556',
			name: 'Thiago B. Caldera',
			avatar: ''
		}
	},
	{
		id: '8',
		type: 'message',
		source: 'recived',
		message: 'Coma a confirmação do recebimento, pedimos a gentileza que deixe sua avaliação de nosso atentimento.',
		time: 'Thu May 18 2023 09:40:00 GMT-0500 (Acre Standard Time)',
		user: {
			uid: 'aw53f334556',
			name: 'Emerson',
			avatar: ''
		}
	},
	{
		id: '9',
		type: 'message',
		source: 'recived',
		message: 'Coma a confirmação do recebimento, pedimos a gentileza que deixe sua avaliação de nosso atentimento.',
		time: 'Thu May 18 2023 09:40:00 GMT-0500 (Acre Standard Time)',
		user: {
			uid: 'aw53f334556',
			name: 'Emerson',
			avatar: ''
		}
	},
	{
		id: '10',
		type: 'message',
		source: 'recived',
		message: 'Coma a confirmação do recebimento, pedimos a gentileza que deixe sua avaliação de nosso atentimento.',
		time: 'Thu May 18 2023 09:40:00 GMT-0500 (Acre Standard Time)',
		user: {
			uid: 'aw53f334556',
			name: 'Emerson',
			avatar: ''
		}
	},
	{
		id: '11',
		type: 'message',
		source: 'recived',
		message: 'Coma a confirmação do recebimento, pedimos a gentileza que deixe sua avaliação de nosso atentimento.',
		time: 'Thu May 18 2023 09:40:00 GMT-0500 (Acre Standard Time)'
	},
	{
		id: '12',
		type: 'message',
		source: 'recived',
		message: 'Coma a confirmação do recebimento, pedimos a gentileza que deixe sua avaliação de nosso atentimento.',
		time: 'Thu May 18 2023 09:40:00 GMT-0500 (Acre Standard Time)',
		user: {
			uid: 'aw53f334556',
			name: 'Emerson',
			avatar: ''
		}
	}
];
