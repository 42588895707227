import { notification } from '../services/notificationService';

export async function clearFCM(userId: string) {
	const groupToken = localStorage.getItem('fcmGroupToken');
	const fcmToken = localStorage.getItem('fcmToken');
	if (groupToken && fcmToken) {
		await notification.removeGroup({ groupName: userId, groupToken, tokens: [fcmToken] });
		localStorage.removeItem('fcmGroupToken');
		localStorage.removeItem('fcmToken');
	}
}
