import { Button, ButtonProps } from '@mui/material';
import React, { PropsWithChildren, ReactNode } from 'react';

interface ButtonCustomProps extends ButtonProps {
	children: ReactNode;
}

const ButtonCustom: React.FC<PropsWithChildren<ButtonCustomProps>> = props => {
	return (
		<Button fullWidth {...props} sx={{ height: '50px', ...props.sx }}>
			{props.children}
		</Button>
	);
};

export default ButtonCustom;
