import React from 'react';

import CustomerList from './components/CustomerList';
import HeaderPage from '../../components/HeaderPage';
import LayoutBase from '../../components/LayoutBase';

const LawsuitsRequest: React.FC = () => {
	return (
		<LayoutBase>
			<HeaderPage
				title="Meus Processos"
				description="Clique no vínculo para visualizar os processos"
				icon="/images/icons/IconHammer.svg"
			/>
			<CustomerList />
		</LayoutBase>
	);
};

export default LawsuitsRequest;
