import styled from 'styled-components';

interface props {
	open?: boolean;
}

export const Hr = styled.div`
	border-bottom: 4px solid #e5dbd2;
	margin: 20px 0;
`;

export const Container = styled.div<props>`
	display: ${props => (props.open ? 'flex' : 'none')};
	position: absolute;
	top: 130px;
	flex-direction: column;
	width: 350px;
	height: calc(100vh - 130px);
	padding: 20px;
	z-index: 9;
	box-shadow: 5px 0px 50px rgba(0, 0, 0, 0.5);
	background-color: #f7f5f3;
	overflow-y: auto;

	@media (max-width: 400px) {
		width: 100%;
	}
`;

export const WrapperButton = styled.button`
	display: flex;
	align-items: center;
	gap: 20px;
	padding: 20px 0;
	cursor: pointer;
	border: none;
	background: transparent;
`;

export const WrapperLogoMarum = styled.div`
	display: flex;
	padding: 8px;
	border-radius: 50%;
	background-color: #fff;
	box-shadow: 5px 0px 20px rgba(0, 0, 0, 0.2);
`;
