import styled from 'styled-components';

export const Carousel = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	max-height: 450px;

	@media (max-width: 1000px) {
	}
`;

export const WrapperImage = styled.div`
	display: flex;
	height: 350px;
	position: relative;
	overflow: hidden;
`;

export const WrapperTitle = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
	padding: 20px;
	gap: 10px;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 150px;
	background: linear-gradient(to top, rgba(0, 0, 0, 6), rgba(0, 0, 0, 0));
`;

export const Carousel__Description = styled.h3`
	color: #fff;
	font-weight: 1;
	margin: 0;
	font-size: 16px;
	text-transform: uppercase;
`;

export const Carousel__Title = styled.h2`
	color: #fff;
	margin: 0;
	font-size: 24px;
	font-weight: bold;
	@media (max-width: 1000px) {
		font-size: 1.5em;
	}
`;

export const PositionIndetifier = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	margin-top: 20px;
`;

export const PositionIndetifier__WrapperButton = styled.div`
	display: flex;
	width: max-content;
	text-align: center;
	gap: 17px;
`;

export const PositionIndetifier__Button = styled.button`
	min-width: 11px;
	min-height: 11px;
	max-width: 11px;
	max-height: 11px;
	background: #999;
	border-radius: 50%;
	border: none;
	padding: 0;
	cursor: pointer;
`;
