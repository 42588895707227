import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
	height: max-content;
	img {
		width: 70px;
		height: 70px;
	}
	div {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-start;
		height: max-content;
	}
	h2 {
		color: #000;
		margin: 0;
	}
	h3 {
		font-size: 12px;
		margin: 0;
		font-weight: normal;
	}
	@media (max-width: 1000px) {
		justify-content: center;
		img {
			width: 60px;
			height: 60px;
		}
		h2 {
			font-size: 18px;
		}
		h3 {
			font-size: 10px;
		}
	}
`;
