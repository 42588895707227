import { TextField, TextFieldProps, Typography } from '@mui/material';
import React from 'react';

interface TextAreaCustomProps {
	customlabel?: string;
}

const TextAreaCustom: React.FC<TextAreaCustomProps & TextFieldProps> = props => {
	return (
		<>
			{props.customlabel && (
				<Typography
					variant="h2"
					sx={{
						fontSize: '18px',
						fontWeight: '500',
						marginBottom: '5px',
						textTransform: 'uppercase'
					}}
				>
					{props.customlabel}
				</Typography>
			)}
			<TextField
				rows={10}
				multiline
				{...props}
				sx={{
					width: '100%',
					borderRadius: '0',
					marginBottom: '40px'
				}}
			/>
		</>
	);
};

export default TextAreaCustom;
