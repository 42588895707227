import { ThemeProvider } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import './assets/globalStyle.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import theme from './assets/theme';

function App() {
	const navigate = useNavigate();
	useEffect(() => {
		navigator.serviceWorker.addEventListener('message', event => {
			if (!event.data.action) {
				return;
			}

			switch (event.data.action) {
				case 'redirect-from-notificationclick':
					navigate(event.data.path);
					break;
				// no default
			}
		});
	}, []);
	return (
		<>
			<ThemeProvider theme={theme}>
				<Outlet />
			</ThemeProvider>
		</>
	);
}

export default App;
