import React from 'react';

import RequestServiceForm from './components/RequestServiceForm';
import HeaderPage from '../../components/HeaderPage';
import LayoutBase from '../../components/LayoutBase';

const RequestService = () => {
	return (
		<LayoutBase>
			<HeaderPage title="Solicitar Serviço" description="Descreva a Solicitação" icon="/images/icons/IconRequest.svg" />
			<RequestServiceForm />
		</LayoutBase>
	);
};

export default RequestService;
