import { Close } from '@mui/icons-material';
import { Box } from '@mui/material';
import React, { ReactNode } from 'react';

interface ModalProps {
	children: ReactNode;
	onClose: () => void;
	isChat?: boolean;
}
const Modal: React.FC<ModalProps> = props => {
	const Content = Box;
	return (
		<Box
			sx={{
				position: 'fixed',
				top: 0,
				right: 0,
				bottom: 0,
				left: 0,
				padding: '0 10px',
				height: '100%',
				zIndex: 1000,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				background: 'rgba(255, 255, 255, 0.8)'
			}}
		>
			<Content
				sx={{
					display: 'flex',
					width: props.isChat ? '800px' : '600px',
					height: 'max-content',
					maxHeight: '98%',
					minHeight: '200px',
					// marginTop: '80px',
					borderRadius: '4px',
					background: '#fff',
					padding: props.isChat ? '10px' : '20px',
					position: 'relative',
					boxShadow: '5px 0px 30px rgba(0, 0, 0, 0.2)'
				}}
			>
				<Close
					onClick={() => {
						props.onClose();
					}}
					sx={{
						position: 'absolute',
						fontSize: '28px',
						top: '20px',
						...(props.isChat && { left: '20px' }),
						...(!props.isChat && { right: '25px' }),
						color: '#c9c9c9',
						cursor: 'pointer'
					}}
				/>
				{props.children}
			</Content>
		</Box>
	);
};

export default Modal;
