import styled from 'styled-components';

interface Props {
	position?: 'start' | 'middle' | 'end' | 'only';
}
interface AvatarProps {
	show: boolean;
}

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const WrappetTitle = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 10px 0 20px 0;
`;

export const Title = styled.h3`
	font-size: 14px;
	font-weight: 600;
	margin: 0;
`;
export const DescriptionTitle = styled.h3`
	font-size: 10px;
	font-weight: 400;
	margin: 0;
	text-transform: uppercase;
`;

export const WrapperMsgs = styled.div`
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 0 10px;
`;

export const Hr = styled.h3`
	width: 100%;
	border-bottom: 2px solid #e9e9e9;
`;

export const MsgsTime = styled.h3`
	width: 100%;
	color: #a9a9a9;
	font-size: 10px;
	font-weight: 100;
	text-transform: uppercase;
	text-align: center;
	margin-top: 20px;
`;

export const WrapperAvatar = styled.div`
	display: flex;
	align-items: flex-end;
	min-width: 20%;
	gap: 10px;
`;

const AvatarCSS = `
	display: flex;
	width: 55px;
	height: 55px;
	border-radius: 50%;
	background: #fff;
	box-shadow: 5px 0px 20px rgba(0, 0, 0, 0.2);
	margin-bottom: 5px;

	@media (max-width: 769px) {
    width: 30px;
		height: 30px;
  }
`;

export const AvatarImage = styled.img<AvatarProps>`
	${AvatarCSS}
	opacity: ${props => (props.show ? 1 : 0)};
`;

export const AvatarText = styled.div<AvatarProps>`
	${AvatarCSS}
	align-items: center;
	justify-content: center;
	opacity: ${props => (props.show ? 1 : 0)};
`;

const msgXPadding = '10px';

export const WrapperSentMsg = styled.div<Props>`
	position: relative;
	display: flex;
	width: fit-content;
	max-width: 90%;
	min-width: 20%;
	flex-direction: column;
	align-self: center;
	justify-self: center;
	margin-left: auto;
	background: #e9e9e9;
	padding: 20px ${msgXPadding};
	border: 2px solid #d9d9d9;
	border-radius: 35px;
	border-bottom-right-radius: ${props =>
		props.position == 'only' || props.position == 'start' || props.position == 'middle' ? '10px' : '35px'};
	border-top-right-radius: ${props => (props.position == 'end' || props.position == 'middle' ? '10px' : '35px')};
	margin-bottom: 4px;
	@media (max-width: 600px) {
		min-width: 40%;
	}
`;

export const WrapperSentFile = styled.button<Props>`
	position: relative;
	display: flex;
	width: fit-content;
	max-width: 90%;
	min-width: 20%;
	flex-direction: column;
	align-self: center;
	justify-self: center;
	margin-left: auto;
	background: #e9e9e9;
	padding: 20px 0px;
	padding-left: 30px;
	padding-right: ${msgXPadding};
	border: 2px solid #d9d9d9;
	border-radius: 35px;
	border-bottom-right-radius: ${props =>
		props.position == 'only' || props.position == 'start' || props.position == 'middle' ? '10px' : '35px'};
	border-top-right-radius: ${props => (props.position == 'end' || props.position == 'middle' ? '10px' : '35px')};
	margin-bottom: 4px;
	cursor: pointer;
	@media (max-width: 600px) {
		min-width: 40%;
	}
`;
export const SentMsg = styled.h3`
	color: #444;
	font-size: 14px;
	font-weight: 100;
	min-width: 20%;
	width: 100%;
	word-break: break-all;
	margin: 0;
`;
export const MsgTimeSent = styled.h3`
	position: absolute;
	bottom: 6px;
	right: 0px;
	color: #a8a8a8;
	font-size: 10px;
	font-weight: 100;
	margin: 0;
	margin-right: ${msgXPadding};
	white-space: nowrap;
`;

export const WrapperRecivedMsg = styled.div<Props>`
	position: relative;
	display: flex;
	width: fit-content;
	max-width: 70%;
	min-width: 20%;
	flex-direction: column;
	align-self: center;
	justify-self: center;
	margin-right: auto;
	padding: 20px ${msgXPadding};
	padding-right: 40px;
	border: 2px solid #e9e9e9;
	border-radius: 35px;
	border-bottom-left-radius: ${props =>
		props.position == 'only' || props.position == 'start' || props.position == 'middle' ? '10px' : '35px'};
	border-top-left-radius: ${props => (props.position == 'end' || props.position == 'middle' ? '10px' : '35px')};
	margin-bottom: 4px;
	background: #fff;
	@media (max-width: 600px) {
		min-width: 40%;
	}
`;

export const WrapperRecivedFile = styled.button<Props>`
	position: relative;
	display: flex;
	width: fit-content;
	max-width: 70%;
	min-width: 20%;
	flex-direction: column;
	align-self: center;
	justify-self: center;
	margin-right: auto;
	padding: 20px ${msgXPadding};
	padding-right: 30px;
	border: 2px solid #e9e9e9;
	border-radius: 35px;
	border-bottom-left-radius: ${props =>
		props.position == 'only' || props.position == 'start' || props.position == 'middle' ? '10px' : '35px'};
	border-top-left-radius: ${props => (props.position == 'end' || props.position == 'middle' ? '10px' : '35px')};
	margin-bottom: 4px;
	background: #fff;
	cursor: pointer;
	@media (max-width: 600px) {
		min-width: 40%;
	}
`;

export const RecivedMsg = styled.h3`
	color: #444;
	font-size: 14px;
	font-weight: 100;
	margin: 0;
`;
export const MsgTimeRecived = styled.h3`
	position: absolute;
	bottom: 6px;
	left: 0px;
	color: #a8a8a8;
	font-size: 10px;
	font-weight: 100;
	margin: 0;
	margin-left: ${msgXPadding};
	white-space: nowrap;
`;

export const WrapperIconAttach = styled.div`
	display: flex;
	position: relative;
	width: 100%;
`;

export const IconAttach = styled.div`
	position: absolute;
	top: -13px;
	right: -25px;
	transform: rotate(-30deg);
`;
export const IconAttachSent = styled.div`
	position: absolute;
	top: -6px;
	left: -25px;
	transform: rotate(30deg);
`;
export const WapperFinished = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	margin-bottom: 10px;
`;
export const TextFinished = styled.h3`
	color: #6da935;
	font-size: 12px;
	font-weight: 100;
	text-transform: uppercase;
`;
export const WapperButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	margin-bottom: 10px;
`;

export const WrapperSendMsgInput = styled.div`
	display: flex;
	align-items: start;
	justify-content: center;
	gap: 10px;
	margin-bottom: 10px;
`;

export const ButtonAttach = styled.div<{ disable: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #999;
	min-width: 40px;
	height: 40px;
	border-radius: 4px;
	position: relative;
	pointer-events: ${({ disable }) => (disable ? 'none' : '')};

	cursor: pointer;
`;

export const InputFile = styled.input`
	position: absolute;
	opacity: 0;
	cursor: pointer;
	width: 40px;
	height: 40px;
`;
