import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import db from '../config/firebaseDb';
import type { ProcedureAstrea } from '../@types/procedure';
import { removeHTMLTags } from '../utils/removeHTMLTags';

export async function fetchAstreaCases(CodCliente: string) {
	const q = query(
		collection(db, 'PCasosAstrea'),
		where('Acesso', '==', 'Público'),
		where('CodCliente', '==', CodCliente),
		orderBy('DtUltimoHistorico', 'desc')
	);

	const snap = await getDocs(q);

	const cases = snap.docs.map(doc => {
		const data = doc.data();
		for (const key in data) {
			if (data[key]) {
				if (typeof data[key] === 'string') {
					data[key] = removeHTMLTags(data[key]);
				}
			}
		}
		return {
			...data,
			Id: doc.id
		} as ProcedureAstrea;
	});

	return cases;
}
