import { Box } from '@mui/material';
import React, { createContext, ReactNode, useEffect, useState } from 'react';

import Sidebar from '../Sidebar';
import Topbar from '../Topbar';

// import { Container } from './styles';

interface LayoutBaseContextProps {
	showSidebar: boolean;
	toggleSidebar: () => void;
}

export const LayoutBaseContext = createContext<LayoutBaseContextProps>({
	showSidebar: true,
	toggleSidebar: () => {}
});

// const LayoutBaseProvider: React.FC<{ children: ReactNode }> = ({
//   children,
// }) => {
//   const [showSidebar, setShowSidebar] = useState(true);

//   const toggleSidebar = () => {
//     setShowSidebar((prevSidebar) => (prevSidebar ? false : true));
//   };

//   const contextValue = {
//     showSidebar,
//     toggleSidebar,
//   };

//   return (
//     <LayoutBaseContext.Provider value={contextValue}>
//       {children}
//     </LayoutBaseContext.Provider>
//   );
// };

function getCurrentDimension() {
	return {
		width: window.innerWidth,
		height: window.innerHeight
	};
}

interface LayoutBaseProps {
	children: ReactNode;
}

const LayoutBase: React.FC<LayoutBaseProps> = props => {
	const [screenSize, setScreenSize] = useState(getCurrentDimension());
	const [showSidebar, setShowSidebar] = useState(true);

	const toggleSidebar = () => {
		setShowSidebar(prevSidebar => (prevSidebar ? false : true));
	};

	const contextValue = {
		showSidebar,
		toggleSidebar
	};

	useEffect(() => {
		const updateDimension = () => {
			setScreenSize(getCurrentDimension());
		};
		window.addEventListener('resize', updateDimension);

		if (screenSize.width < 800) {
			setShowSidebar(false);
		} else {
			setShowSidebar(true);
		}

		return () => {
			window.removeEventListener('resize', updateDimension);
		};
	}, [screenSize]);

	return (
		<LayoutBaseContext.Provider value={contextValue}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center'
				}}
			>
				<Topbar />
				<Sidebar />
				<Box
					sx={{
						marginLeft: showSidebar ? '350px' : '0px',
						display: 'flex',
						justifyContent: 'center',
						'@media (max-width: 800px)': {
							marginLeft: '0px'
						}
					}}
				>
					<Box
						sx={{
							width: '100%',
							maxWidth: '1300px',
							height: 'calc(100vh - 130px)',
							padding: '40px',
							overflowY: 'auto'
						}}
					>
						{props.children}
					</Box>
				</Box>
			</Box>
		</LayoutBaseContext.Provider>
	);
};

export default LayoutBase;
