import styled from 'styled-components';

interface Props {
	showsidebar?: number;
}

export const Container = styled.div`
	display: flex;
	height: 130px;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
	gap: 40px;
	background-color: white;
	z-index: 10;
	box-shadow: 5px 0px 20px rgba(0, 0, 0, 0.3);
`;

export const WrapperIcon = styled.div<Props>`
	display: flex;
	min-width: 60px;
	min-height: 60px;
	max-width: 60px;
	max-height: 60px;
	align-items: center;
	justify-content: center;
	border: 3px solid;
	border: 1px solid red;
	border-color: ${props => (props.showsidebar ? '#fff' : '#000')};
	background-color: ${props => (props.showsidebar ? '#000' : '#fff')};
`;

export const IsDesktop = styled.div`
	@media (max-width: 500px) {
		display: none;
	}
`;
