import { Box, TextFieldProps, Typography } from '@mui/material';
import * as React from 'react';

import SvgIcon from '../SvgIcon';

interface TextCustomProps {
	externallabel?: string;
	startIcon?: string;
	text: string;
}

const TextCustom: React.FC<TextCustomProps & TextFieldProps> = props => {
	const Row = Box;

	return (
		<Box>
			{props.externallabel && (
				<Typography
					variant="h2"
					sx={{
						fontSize: '18px',
						fontWeight: '500',
						marginBottom: '5px',
						textTransform: 'uppercase'
					}}
				>
					{props.externallabel}
				</Typography>
			)}
			<Row
				sx={{
					display: 'flex',
					alingItem: 'center',
					gap: '14px',
					marginTop: '12px'
				}}
			>
				{props.startIcon && <SvgIcon path={props.startIcon} size={24} />}
				<Typography
					variant="h4"
					sx={{
						fontSize: '14px',
						lineHeight: '26px'
					}}
				>
					{props.text}
				</Typography>
			</Row>
		</Box>
	);
};

export default TextCustom;
