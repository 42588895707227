import React from 'react';

import DocumentsForm from './components/DocumentsForm';
import HeaderPage from '../../components/HeaderPage';
import LayoutBase from '../../components/LayoutBase';

const Documents: React.FC = () => {
	return (
		<LayoutBase>
			<HeaderPage title="Documentos" icon="/images/icons/IconDocumentRed.svg" description="Documentos finalizados" />
			<DocumentsForm />
		</LayoutBase>
	);
};

export default Documents;
