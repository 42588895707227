import { Close } from '@mui/icons-material';
import { Box } from '@mui/material';
import React, { ReactNode } from 'react';

interface ModalLoginProps {
	children: ReactNode;
	onClose: () => void;
}
const ModalLogin: React.FC<ModalLoginProps> = props => {
	const Content = Box;
	return (
		<Box
			sx={{
				position: 'fixed',
				top: 0,
				right: 0,
				bottom: 0,
				left: 0,
				zIndex: 1000,
				background: 'rgba(0, 0, 0, 0.8)'
			}}
		>
			<Content
				sx={{
					display: 'flex',
					width: '100%',
					height: '100%',
					marginTop: '80px',
					'@media (max-width: 769px)': {
						marginTop: 0
					},
					borderTopLeftRadius: '10px',
					borderTopRightRadius: '10px',
					background: '#fff',
					padding: '20px',
					position: 'relative',
					overflowY: 'auto'
				}}
			>
				<Close
					onClick={() => {
						props.onClose();
					}}
					sx={{
						position: 'absolute',
						top: '25px',
						right: '25px',
						color: '#999',
						cursor: 'pointer'
					}}
				/>
				{props.children}
			</Content>
		</Box>
	);
};

export default ModalLogin;
