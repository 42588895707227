import { Box, Typography } from '@mui/material';
import { collection, getDocs, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { RequestData, UserData } from './../../../../utils/typesData';
import * as S from './styles';
import { dataPropsCli } from '../../../../@types/customer';
import CardListItem from '../../../../components/CardListItem';
import SelectCustom from '../../../../components/Globals/SelectCustom';
import Loading from '../../../../components/Loading';
import db, { auth } from '../../../../config/firebaseDb';
import { formatDatetimeToString } from '../../../../utils/dateTime';

export const ConsultRequestForm: React.FC = () => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [user, setUser] = useState<UserData>();
	const [loading, setLoading] = useState(true);
	const [listReq, setListReq] = useState<RequestData[]>([]);

	const [listCli, setListCli] = useState<dataPropsCli[]>([]);
	const [selectedCli, setSelectedCli] = useState('');
	const [selectedCliId, setSelectedCliId] = useState('');

	const fetchDataCli = async () => {
		if (user?.uid) {
			const q = query(
				collection(db, 'Cliente'),
				where('Usuario', 'array-contains', user?.uid),
				orderBy('NomeEmpresa', 'asc')
			);
			try {
				const querySnap = await getDocs(q);
				const dataCli: dataPropsCli[] = [];
				querySnap.forEach(doc => {
					const { NomeApelido, NomeEmpresa, AstreaCod } = doc.data();
					dataCli.push({
						id: doc.id,
						label: NomeApelido ? NomeApelido : NomeEmpresa,
						name: NomeApelido ? NomeApelido : NomeEmpresa,
						astreaCod: AstreaCod
					});
				});
				setListCli(dataCli);
				setSelectedCli(searchParams.get('cli') ?? '');
				setSelectedCliId(searchParams.get('id') ?? '');
			} catch (error) {
				console.error('[fetchDataCli] Err: ', error);
			}
		}
	};

	useEffect(() => {
		fetchDataCli();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		//console.log('[Request.List] init');

		const unsubscribe = auth.onAuthStateChanged(authUser => {
			if (authUser) {
				const { uid, email, displayName } = authUser;
				setUser({
					uid,
					Email: email || '',
					Nome: displayName || ''
				});
			}
		});
		return () => unsubscribe();
	}, []);

	useEffect(() => {
		let subscrib = function () {};
		try {
			setLoading(true);
			if (user == undefined || user == null) return;
			//const uid = user.uid;

			//const q = query(collection(db, 'AppContato'), where('UidSolicitacao', '==', uid), orderBy('Protocolo', 'desc'));
			const q = query(
				collection(db, 'AppContato'),
				where('ClienteId', '==', selectedCliId),
				orderBy('Protocolo', 'desc')
			);
			subscrib = onSnapshot(q, querySnapshot => {
				//console.log('Documento >> UPDATE LISTENER');
				const data: RequestData[] = [];
				querySnapshot.forEach(doc => {
					const oItem: RequestData = {
						id: doc.id,
						Assunto: doc.data().Assunto || '',
						ClienteId: doc.data().ClienteId || '',
						Cliente: doc.data().Cliente || '',
						ClienteLeu: doc.data().ClienteLeu || false,
						DthSolicitacao: doc.data().DthSolicitacao.toDate(),
						PrioridadeId: doc.data().PrioridadeId || 0,
						Protocolo: doc.data().Protocolo || '',
						Responsavel: doc.data().Responsavel || '',
						TipoServico: doc.data().TipoServico || 'Outros', //novo
						ServicoDes: doc.data().ServicoDes || '',
						StatusDes: doc.data().StatusDes || '',
						StatusId: doc.data().StatusId || '',
						Avaliacao: doc.data().Avaliacao || 0
					};
					data.push(oItem);
				});
				setListReq(data);
			});
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}

		setSearchParams({
			cli: selectedCli,
			id: selectedCliId
		});

		return () => {
			subscrib();
			// console.log('<<< [Request.List] Cancel Listner >>>');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, selectedCli]);

	//-----------------------------------//-----------------------------------

	return (
		<S.Container>
			<SelectCustom
				externallabel="SELECIONE O VÍNCULO"
				id={selectedCliId}
				setId={setSelectedCliId}
				value={selectedCli}
				setValue={setSelectedCli}
				data={listCli}
			/>
			<Loading open={loading} />
			{listReq.length > 0
				? listReq.map(request => (
						<React.Fragment key={request.id}>
							<CardListItem
								title={request.TipoServico}
								description={request.Assunto}
								status={{ code: request.StatusId }}
								id={request.id}
								date={formatDatetimeToString(request.DthSolicitacao)}
								code={'Protocolo: ' + request.Protocolo}
								handleClick={function (): void {
									const serializedItem = JSON.stringify(request);
									navigate(`/request_item/${request.id}`, { state: { item: serializedItem } });
								}}
							/>
						</React.Fragment>
				  ))
				: selectedCli && (
						<Box justifyContent={'center'} alignItems={'center'}>
							<Typography>Nenhum registro encontrado</Typography>
						</Box>
				  )}
		</S.Container>
	);
};
