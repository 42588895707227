import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import RequestItemForm from './components/RequestItemForm';
import HeaderPage from '../../components/HeaderPage';
import LayoutBase from '../../components/LayoutBase';
import db from '../../config/firebaseDb';
import { RequestData } from '../../utils/typesData';

const RequestItem = () => {
	const [data, setData] = useState<RequestData>();

	const location = useLocation();
	const { item } = (location.state as { item: string }) || {};

	const paths = location.pathname.split('/');
	const ChatId = paths[paths.length - 1]; //Id de AppContato
	let deserializedItem: RequestData | undefined;
	try {
		deserializedItem = item ? JSON.parse(item) : undefined;
	} catch (error) {
		console.error('[RequestItem] Error:', error);
	}

	useEffect(() => {
		if (!deserializedItem) {
			const docRef = doc(db, 'AppContato', ChatId);
			getDoc(docRef).then(doc => {
				const data = {
					...doc.data(),
					id: doc.id
				} as RequestData;
				setData(data);
			});
		} else {
			setData(deserializedItem);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		data && (
			<LayoutBase>
				<HeaderPage title={'Protocolo: ' + data.Protocolo || '-'} icon="/images/icons/IconTimeRed.svg" />
				<RequestItemForm {...data} />
			</LayoutBase>
		)
	);
};

export default RequestItem;
