import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppContext } from '../../providers/AppProvider';
import Loading from '../Loading';

const ProtectedRoute = ({ element }: { element: React.ReactElement }) => {
	const { user, loading } = useAppContext();
	const location = useLocation();

	if (loading) {
		return <Loading open />; // Ou um spinner, enquanto verifica o estado de autenticação
	}

	return user ? element : <Navigate to="/" state={{ from: location }} replace />;
};

export default ProtectedRoute;
