import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
	justify-content: center;
	align-items: center;
	position: relative;
	margin-bottom: 40px;

	& > svg {
		position: absolute;
		top: 20px;
		left: 0;
		color: #777;
		cursor: pointer;
	}

	& > h2 {
		font-size: 18px;
		color: #999;
		margin: 0;
		font-weight: 1;
		margin-left: 20px;

		@media (max-width: 400px) {
			font-size: 14px;
		}
	}
`;

export const WrapperTitle = styled.div`
	display: flex;
	gap: 20px;
	margin-left: 20px;
	align-items: center;

	& > h1 {
		font-size: 28px;
		font-weight: bold;
		margin: 0;

		@media (max-width: 400px) {
			font-size: 22px;
		}
	}
`;
