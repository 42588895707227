import React from 'react';

import { ProceduresForm } from './components/ProceduresForm';
import HeaderPage from '../../components/HeaderPage';
import LayoutBase from '../../components/LayoutBase';

const Procedures: React.FC = () => {
	return (
		<LayoutBase>
			<HeaderPage
				title="Procedimentos"
				icon="/images/icons/IconProcedureRed.svg"
				description="Demandas em curso / finalizada"
			/>
			<ProceduresForm />
		</LayoutBase>
	);
};

export default Procedures;
