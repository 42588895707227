import styled from 'styled-components';

export const Container = styled.div``;

export const WrapperBanner = styled.div`
	span {
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.3);
	}
`;

export const WrapperCards = styled.div`
	display: flex;
	margin-top: 40px;
	gap: 20px;
	flex-wrap: wrap;
`;

export const Card = styled.div`
	display: flex;
	flex: 1;
	min-width: 225px;
	height: 120px;
	padding: 15px;
	background: #fff;
	box-shadow: 5px 0px 20px rgba(0, 0, 0, 0.2);
	cursor: pointer;

	@media (max-width: 1000px) {
		flex: none;
		width: 100%;
	}
`;

export const Card__WrapperTitle = styled.div`
	display: flex;
	flex-direction: column;
	flex: 2;
	padding-right: 10px;
	gap: 5px;
`;

export const Card__Title = styled.div`
	font-size: 20px;
	font-weight: bold;
`;

export const Card__Description = styled.div`
	font-size: 13px;
	letter-spacing: 1px;
	color: #999;
`;
export const Card__WrapperIcon = styled.div`
	display: flex;
	flex: 1;
	min-width: 45px;
	box-sizing: border-box;
	justify-content: flex-end;
	align-items: flex-end;
`;
