import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	components: {
		MuiTextField: {
			styleOverrides: {
				root: {
					'& label.Mui-focused': {
						color: '#777' // Cor do label quando o campo está em foco
					},
					'& .MuiInput-underline:after': {
						borderBottomColor: 'green' // Cor da linha de baixo quando o campo está em foco
					},
					'& .MuiOutlinedInput-root': {
						'& fieldset': {
							width: '100%',
							borderColor: '#a9a9a9' // Cor da borda do campo
						},

						'&:hover fieldset': {
							borderWidth: '2px',
							borderColor: '#777' // Cor da borda quando o campo está em hover
						},
						'&.Mui-focused fieldset': {
							borderWidth: '3px',
							borderColor: '#777' // Cor da borda quando o campo está em foco
						}
					}
				}
			}
		}
	}
});

export default theme;
