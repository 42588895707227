import { Box } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';

import * as S from './styles';
import InputCustom from '../../../../components/Globals/InputCustom';
import TextAreaCustom from '../../../../components/Globals/TextAreaCustom';
import TextCustom from '../../../../components/Globals/TextCustom';
import { ProcedureData } from '../../../../utils/typesData';

const ProcedureItemForm = () => {
	const location = useLocation();
	// const [readOnly, setReadOnly] = useState(true);
	const { item } = (location.state as { item: string }) || {};

	const Row = Box;

	//--------------------------------------------------------
	let deserializedItem: ProcedureData | undefined;
	try {
		deserializedItem = item ? JSON.parse(item) : undefined;
	} catch (error) {
		console.error('Erro ao desserializar o objeto:', error);
	}

	if (!deserializedItem) {
		return <div>Item inválido</div>;
	}

	const { Titulo, TituloProcesso, Tipo, Data, Responsavel, NumeroProcesso, Observacoes } = deserializedItem;

	//--------------------------------------------------------

	return (
		<S.Container>
			<TextAreaCustom rows={2} customlabel="TÍTULO" value={Titulo} />
			<Row
				sx={{
					display: 'flex',
					gap: '40px',
					justifyContent: 'space-between',
					maxWidth: '80%',
					flexWrap: 'wrap',
					marginBottom: '40px'
				}}
			>
				<TextCustom externallabel="TIPO" text={Tipo} />
				<TextCustom externallabel="DATA" text={Data} />
				<TextCustom externallabel="RESPONSÁVEL" text={Responsavel} />
			</Row>
			<InputCustom externallabel="Nº DO PROCESSO" value={NumeroProcesso} />
			<TextAreaCustom rows={2} customlabel="TÍTULO DO PROCESSO" value={TituloProcesso} />
			<TextAreaCustom rows={5} customlabel="OBSERVAÇÕES" value={Observacoes} />
			{/* <Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					flex: 1,
					gap: '20px',
					alignItems: 'center'
				}}
			>
				<ButtonCustom
					variant="contained"
					color="error"
					sx={{ width: '350px' }}
					onClick={() => {
						//handleGoHome();
					}}
				>
					VOLTAR PARA HOME
				</ButtonCustom>
			</Box> */}
		</S.Container>
	);
};

export default ProcedureItemForm;
