import { ArrowBackIosNew } from '@mui/icons-material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import SvgIcon from '../../components/Globals/SvgIcon';
import * as S from '../../components/HeaderPage/styles';

interface HeaderPageProps {
	title: string;
	description?: string;
	icon: string;
}

const HeaderPage: React.FC<HeaderPageProps> = props => {
	const navigate = useNavigate();

	return (
		<S.Container>
			<ArrowBackIosNew
				onClick={() => {
					//navigate('/home');
					navigate(-1);
				}}
			/>
			<S.WrapperTitle>
				<SvgIcon path={props.icon} size={34} color="#d71600" />
				<h1>{props.title}</h1>
			</S.WrapperTitle>
			<h2>{props.description}</h2>
		</S.Container>
	);
};

export default HeaderPage;
