import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as S from './styles';
import Carousel from '../../components/Carousel';
import SvgIcon from '../../components/Globals/SvgIcon';
import LayoutBase from '../../components/LayoutBase';
import { fetchLastPosts } from '../../services/blogService';
import { cards } from '../../utils/mockup';
import { BlogPost } from '../../utils/typesData';

const images = [
	{
		title: 'A Importância de Planejamento Societário',
		description: 'SOLUÇÕES PARA SUA EMPRESA',
		imgPath: '/images/businesMan.jpg'
	},
	{
		title: 'A segurança do seu negócio na palma da mão',
		description: 'SEGURANÇA NO NEGÓCIO',
		imgPath: '/images/business.jpg'
	}
];

const Home: React.FC = () => {
	const navigate = useNavigate();

	const [posts, setPosts] = useState<BlogPost[]>([]);

	const handleClickButton = (link: string) => {
		navigate(link);
	};

	function parseCarousel() {
		return posts.map((apiPost, index: number) => ({
			id: index + 1,
			imgPath: apiPost?.yoast_head_json?.og_image?.[0]?.url ?? '/images/businesMan.jpg',
			title: apiPost?.title?.rendered,
			description:
				'por: ' +
				apiPost?.yoast_head_json.author +
				' | ' +
				new Date(apiPost?.date).toLocaleDateString('pt-BR', {
					day: '2-digit',
					month: '2-digit',
					year: 'numeric'
				}),
			//case: 'TRABALHISTA',
			readMore: false,
			url: apiPost?.link
		}));
	}

	useEffect(() => {
		fetchLastPosts().then(data => setPosts(data));
	}, []);

	return (
		<LayoutBase>
			<S.Container>
				<S.WrapperBanner>
					<Carousel images={posts.length === 0 ? images : parseCarousel()} />
				</S.WrapperBanner>
				<S.WrapperCards>
					{cards.map(card => (
						<S.Card
							key={card.id}
							onClick={() => {
								handleClickButton(card.link);
							}}
						>
							<S.Card__WrapperTitle>
								<S.Card__Title>{card.title}</S.Card__Title>
								<S.Card__Description>{card.description}</S.Card__Description>
							</S.Card__WrapperTitle>
							<S.Card__WrapperIcon>
								<SvgIcon path={card.icon} size={38} color="#d71600" />
							</S.Card__WrapperIcon>
						</S.Card>
					))}
				</S.WrapperCards>
			</S.Container>
		</LayoutBase>
	);
};

export default Home;
