import { Box, MenuItem, Select, TextFieldProps, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';

interface SelectCustomProps {
	externallabel?: string;
	value: string;
	data: dataProps[];
	setValue: Dispatch<SetStateAction<string>>;
	setId: Dispatch<SetStateAction<string>>;
}

interface dataProps {
	id: string;
	label: string;
}

const SelectCustom: React.FC<SelectCustomProps & TextFieldProps> = props => {
	const handleChange = (id: string, label: string) => {
		props.setValue(label);
		props.setId(id);
	};

	return (
		<Box
			sx={{
				marginBottom: '30px',
				width: '100%'
			}}
		>
			{props.externallabel && (
				<Typography
					variant="h2"
					sx={{
						fontSize: '18px',
						fontWeight: '500',
						marginBottom: '5px',
						textTransform: 'uppercase'
					}}
				>
					{props.externallabel}
				</Typography>
			)}
			<Select
				labelId="custom-select-label"
				id={props.id}
				value={props.id}
				fullWidth
				onChange={e => {
					const selectedOption = props.data.find(item => item.id === e.target.value);
					if (selectedOption) handleChange(selectedOption.id, selectedOption.label);
				}}
				sx={{
					'&:focus': {
						outline: 'none',
						border: 'none'
					}
				}}
			>
				{props.data.map(item => (
					<MenuItem key={item.id} value={item.id}>
						{item.label}
					</MenuItem>
				))}
			</Select>
		</Box>
	);
};

export default SelectCustom;
