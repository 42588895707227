import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	gap: 20px;
	width: 100%;
	flex-direction: column;
	align-items: center;
	padding: 30px 0;

	h1 {
		font-size: 18px;
		font-weight: 600;
		text-transform: uppercase;
		margin: 0;
	}
	h2 {
		font-size: 22px;
		font-weight: 100;
		margin: 0;
	}
`;

export const Title = styled.div`
	text-align: center;
	font-size: 32px;
	font-weight: bold;
	padding: 0 110px;
`;

export const WrapperIcon = styled.div`
	padding: 20px;
	border-radius: 50%;
	background-color: #cc2c2d;

	.cls-1 {
		fill: #fff !important;
	}
`;

export const Body = styled.div`
	width: 100%;
	padding-top: 30px;
`;

export const Hr = styled.div`
	width: 100%;
	height: 2px;
	border-bottom: 4px solid #e5dbd2;
	margin: 30px 0;
`;
