import { onAuthStateChanged, type User } from 'firebase/auth';
import React, { createContext, useContext, useEffect, useState, type ReactNode } from 'react';
import { auth } from '../config/firebaseDb';

interface AppContextProps {
	user: User | null;
	loading: boolean;
}

const AppContext = createContext({} as AppContextProps);

export const useAppContext = () => {
	const context = useContext(AppContext);
	if (!context) {
		throw new Error('Not in context provider');
	}
	return context;
};

export function AppProvider({ children }: { children: ReactNode }) {
	const [user, setUser] = useState<User | null>(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, user => {
			setUser(user);
			setLoading(false);
		});

		return () => unsubscribe(); // Cleanup the listener on unmount
	}, []);

	return <AppContext.Provider value={{ user, loading }}>{children}</AppContext.Provider>;
}
