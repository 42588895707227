import styled from '@emotion/styled';

interface Props {
	isMobile?: boolean;
}

export const Container = styled.div`
	display: flex;
	width: 100%;
	height: 100vh;
	overflow-y: auto;
	margin: 0;
	padding: 0;
`;

export const Sidebar = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	min-width: 600px;
	height: 100%;
	min-height: 100%;
	padding: 60px 90px;
	background: #fff;
	box-shadow: 5px 0px 60px rgba(0, 0, 0, 0.3);
	@media (min-width: 1024px) {
		min-width: 500px;
		padding: 30px 40px;
	}
	@media (max-width: 1000px) {
		display: none;
	}
`;

export const WrapperInfo = styled.div<Props>`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: ${props => (props.isMobile ? '20px 0px' : '55px 0px 50px 0px')};
	font-size: 18px;

	h2 {
		font-size: 22px;
	}

	span {
		font-size: ${props => (props.isMobile ? '14px' : '20px')};
	}

	@media (min-width: 1024px) {
		padding: 10px 0;

		h2 {
			font-size: 20px;
		}

		span {
			font-size: 18px;
		}
	}
`;

export const Row = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
`;

export const Column = styled.div`
	display: flex;
	gap: 2px;
	flex-direction: column;
`;

export const WrapperIconPlus = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 60px;
	min-height: 60px;
	max-width: 60px;
	max-height: 60px;
	border-radius: 50%;
	background-color: #fff;
	box-shadow: 5px 0px 60px rgba(0, 0, 0, 0.3);

	img {
		width: 28px;
	}

	div > p {
		margin: 0;
	}
`;

export const Form = styled.form<Props>`
	display: flex;
	width: 100%;
	align-items: center;
	flex-direction: column;
	gap: 0px;

	h1 {
		font-size: 20px;
	}

	& > input {
		width: 350px;
		height: 25px;
		padding: 10px;
	}
`;

export const Carousel = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 30px 0;
	align-items: center;
	justify-content: flex-start;
	min-width: calc(100vw - 600px);
	@media (max-width: 1000px) {
		min-width: 100vw;
		background-color: #fff;
		justify-content: flex-start;
		overflow-y: auto;
	}
	@media (min-width: 1024px) {
		height: 100%;
		width: 100%;
		justify-content: center;
		overflow: hidden;
	}
`;

export const ContentMobile = styled.div`
	display: none;
	@media (max-width: 1000px) {
		display: flex;
		width: 100%;
		padding: 0 20px;
		flex-direction: column;
		gap: 20px;
	}
`;
