import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import * as S from './styles';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

interface imageProps {
	title: string;
	description: string;
	imgPath: string;
	url?: string;
}
interface CarouselProps {
	images: imageProps[];
}

const Carousel: React.FC<CarouselProps> = props => {
	const theme = useTheme();
	const [activeStep, setActiveStep] = React.useState(0);
	const [loadingImage, setLoadingImage] = React.useState(true);

	const handleStepChange = (step: number) => {
		setActiveStep(step);
	};

	function handleLoadImage() {
		setLoadingImage(false);
	}

	function handleImageClick(url?: string) {
		if (url) window.open(url, '_blank');
	}

	return (
		<S.Carousel>
			<AutoPlaySwipeableViews
				axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
				index={activeStep}
				onChangeIndex={handleStepChange}
				enableMouseEvents
				style={{ width: '100%' }}
			>
				{props.images.map((step, index) => (
					<S.WrapperImage
						key={step.title}
						style={{ cursor: step.url ? 'pointer' : '' }}
						onClick={() => handleImageClick(step.url)}
					>
						{Math.abs(activeStep - index) <= 2 ? (
							<img
								style={{
									height: '100%',
									width: '100%',
									marginBottom: '30px',
									objectFit: 'cover'
								}}
								onLoad={handleLoadImage}
								src={!loadingImage ? step.imgPath : '/images/business.jpg'}
								alt={step.title}
							/>
						) : null}
						<S.WrapperTitle>
							<S.Carousel__Description>{step.description}</S.Carousel__Description>
							<S.Carousel__Title>{step.title}</S.Carousel__Title>
						</S.WrapperTitle>
					</S.WrapperImage>
				))}
			</AutoPlaySwipeableViews>

			<S.PositionIndetifier>
				<S.PositionIndetifier__WrapperButton>
					{props.images.map((_, index) => (
						<S.PositionIndetifier__Button
							key={index}
							onClick={() => {
								handleStepChange(index);
							}}
							style={{ background: activeStep === index ? '#110d28' : '#999' }}
						></S.PositionIndetifier__Button>
					))}
				</S.PositionIndetifier__WrapperButton>
			</S.PositionIndetifier>
		</S.Carousel>
	);
};

export default Carousel;
