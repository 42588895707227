import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import * as S from './styles';
import ButtonCustom from '../../../../components/Globals/ButtonCustom';
import SvgIcon from '../../../../components/Globals/SvgIcon';

interface RequestServiceModalConfirmProps {
	Protocolo: string;
	Assunto: string;
}

const RequestServiceModalConfirm: React.FC<RequestServiceModalConfirmProps> = ({ Protocolo, Assunto }) => {
	const navigate = useNavigate();

	const handleGoHome = () => {
		navigate('/home');
	};
	return (
		<S.Container>
			<S.WrapperIcon>
				<SvgIcon path="/images/icons/IconRequest.svg" size={80} color="white" />
			</S.WrapperIcon>
			<S.Title>Solicitação Enviada com sucesso</S.Title>

			<S.Body>
				<h1>ASSUNTO:</h1>
				<h2>{Assunto}</h2>
				<S.Hr />
				<h1>Nº DO PROTOCOLO:</h1>
				<h2>{Protocolo}</h2>

				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						flex: 1,
						gap: '20px',
						alignItems: 'center',
						marginTop: '60px'
					}}
				>
					<ButtonCustom
						variant="contained"
						color="error"
						sx={{ width: '350px' }}
						onClick={() => {
							handleGoHome();
						}}
					>
						VOLTAR PARA HOME
					</ButtonCustom>
					{/*
					<ButtonCustom
						variant="contained"
						color="error"
						sx={{ width: '350px', background: '#ecd6c7' }}
						onClick={() => {}}
					>
						ENVIAR
					</ButtonCustom>
					 */}
				</Box>
			</S.Body>
		</S.Container>
	);
};

export default RequestServiceModalConfirm;
