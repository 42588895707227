export function formatDatetimeToString(date: Date): string {
	const day: string = String(date.getDate()).padStart(2, '0');
	const month: string = String(date.getMonth() + 1).padStart(2, '0'); // Month starts from 0, so add 1
	const year: number = date.getFullYear();
	const hours: string = String(date.getHours()).padStart(2, '0');
	const minutes: string = String(date.getMinutes()).padStart(2, '0');

	const formattedDate: string = `${day}/${month}/${year} - ${hours}:${minutes}`;

	return formattedDate;
}
