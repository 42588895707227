import { Box, TextField, TextFieldProps, Typography } from '@mui/material';
import React from 'react';

interface InputCustomProps {
	externallabel?: string;
	marginBottom?: string;
}

const InputCustom: React.FC<InputCustomProps & TextFieldProps> = ({ marginBottom, ...props }) => {
	return (
		<Box
			sx={{
				marginBottom: marginBottom ?? '30px',
				width: '100%',
				...props.sx
			}}
		>
			{props.externallabel && (
				<Typography
					variant="h2"
					sx={{
						fontSize: '18px',
						fontWeight: '500',
						marginBottom: '5px',
						textTransform: 'uppercase'
					}}
				>
					{props.externallabel}
				</Typography>
			)}
			<TextField {...props} sx={{ width: 'calc(100% - 18px)' }} />
		</Box>
	);
};

export default InputCustom;
