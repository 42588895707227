import { Cancel, TextSnippet } from '@mui/icons-material';
import React, { CSSProperties } from 'react';

import { UploadFile, UploadFormProps } from './UploadForm';

type UploadListProps = UploadFormProps;

export default function UploadList({ arquivos, setFile }: UploadListProps) {
	return (
		<div style={ContainerListStyle}>
			{arquivos.map(arquivo => (
				<UploadItem key={arquivo.arquivo.name} item={arquivo} setFile={setFile} />
			))}
		</div>
	);
}

interface UploadItemProps {
	item: UploadFile;
	setFile: React.Dispatch<React.SetStateAction<UploadFile[]>>;
}

function UploadItem({ item, setFile }: UploadItemProps) {
	return (
		<div style={ContainerItemStyle}>
			<TextSnippet fontSize="large" />
			<div style={ItemTextStyle}>
				<span>{item.descricao}</span>
				<span>{item.arquivo.name}</span>
			</div>
			<div
				onClick={() =>
					setFile(prev => {
						return prev.filter(file => file.arquivo.name !== item.arquivo.name);
					})
				}
				role="presentation"
				style={{ cursor: 'pointer', display: 'flex', justifyItems: 'center' }}
			>
				<Cancel color="error" />
			</div>
		</div>
	);
}

//List
const ContainerListStyle: React.CSSProperties = {
	display: 'flex',
	gap: '5px',
	width: '100%',
	border: '1px solid black',
	padding: '5px',
	borderRadius: '10px',
	overflow: 'auto',
	maxHeight: '200px',
	flexWrap: 'wrap'
};

//Item
const ContainerItemStyle: CSSProperties = {
	display: 'flex',
	gap: '3px',
	maxHeight: '60px',
	overflow: 'hidden',
	backgroundColor: 'lightgrey',
	padding: '5px',
	borderRadius: '10px',
	alignItems: 'center'
};

const ItemTextStyle: CSSProperties = {
	display: 'flex',
	gap: '3px',
	flexDirection: 'column',
	whiteSpace: 'nowrap',
	overflow: 'hidden'
};
