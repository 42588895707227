import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const ContactDetails: React.FC = () => {
	const { id } = useParams();

	const navigate = useNavigate();

	const handleContact = () => {
		navigate('/');
	};

	return (
		<>
			<h1>Está na página {id}</h1>;<button onClick={() => handleContact()}>Teste</button>
		</>
	);
};

export default ContactDetails;
